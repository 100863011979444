export const environment = {
    production: false,
    mode: 'dev',
    appHost: 'https://dev.healthybazar.com/',
    apiUrl: "https://devapi.healthybazar.com/api/v2/",
    // apiUrl2: "http://167.71.235.83:8081/api/v2/",
    // apiUrl: "http://167.71.235.83:8082/api/v2/",
    //apiUrl: "http://localhost:8080/api/v2/", //local server
    apikey: "e28390d3-8f65-4f24-9d85-b2650cf0433b",

    imageUrl: "https://cdn.healthybazar.com/images/",
    cryptographyToken: "0123456789123456",
    oneSignalAppID: "d6a03460-5f2a-41ad-8717-fb678cafccf7",
    
    webDomain: 'localhost',
    // paytmHost: "https://securegw-stage.paytm.in/",
    // mid: "YqslJt93744167301432",

    paytmHost: "https://securegw.paytm.in/",   // paytm live
    mid: "vuogWg59803884414295",   // paytm live

    corporateUserAppHost: 'http://167.71.235.83:3054/',
    adminHost: 'http://167.71.235.83:3052/',
};
